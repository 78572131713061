import React from "react";
import {map} from "lodash";

import ClinicalNotesTab from "./components/ClinicalNotesTab/ClinicalNotesTab";
import { getClinicalNotesSections } from "./clinicalNotes.sections";

import styles from "./clinicalNotes.module.css";

const ClinicalNotes = ({
        formState,
        ...rest
    })=>{

    const { billingCode = [] } = formState;

    const { handleValueChange=()=>()=>{} } = rest || {}

    const handleFormValuesUpdate = ({
        label
    })=> (content)=>{
        handleValueChange({
            label
        })({
            ...(formState?.[label] || {}),
            ...content
        })
    }

    const clinicalNotesSections = getClinicalNotesSections(billingCode?.[0] || "", handleFormValuesUpdate);

    return (
        <div className={styles.container}>
            {map(clinicalNotesSections,(clinicalNotesProps)=>{
                return  <ClinicalNotesTab {...clinicalNotesProps} />
            })}
        </div>
    )
}

export default ClinicalNotes