import React from "react";

import { getRespectiveNotesType } from "../../notesFormPopup.fields";

import styles from "./body.module.css";

const Body = ({
        formProps={},
        formState={},
        noteType,
        handleFormValuesUpdate = ()=>{},
        ...remainingProps
    }) =>{
    
    const { Component, containerClass, ...rest } = getRespectiveNotesType(handleFormValuesUpdate)[noteType];

    return (
        <div className={`${styles.container} ${containerClass}`}>
            <Component {...formProps} {...rest} {...remainingProps} initialState={formState}/>
        </div>
    )
}

export default Body