import {map} from "lodash";
import moment from "moment";

import { TOTAL_DAYS_IN_MONTH } from "./monthCalender.constants";

import styles from "./monthCalender.module.css";
import { DATE_FORMATS } from "../../../../../../../../constants/dateFormats";

const getWeeklyClassMapping = (data=[])=>{
    return map(data,(value)=>{
        return {
            className : styles.weeklyTextContainer,
            ...value
        }
    })
}

const getDateFormattedData = (monthBack=0, handleUpdateStartDate=()=>{})=>{
    let dateData = new Array(TOTAL_DAYS_IN_MONTH);
    let activeMonth = moment().month();
    let currentMonthNumber = monthBack>activeMonth ? (12-(monthBack%12)-activeMonth)%12  : moment().month()-monthBack%12;
    let currentYearNumber = monthBack>activeMonth ? Math.ceil((monthBack)/12) : 0;

    const currentDate = moment();
    const previousYear = currentDate.subtract(currentYearNumber, 'years').year();

    let startDay = moment(`1-${currentMonthNumber+1}-${previousYear}`,DATE_FORMATS.DATE_MONTH_FULLYEAR).day();
    let totalDays = moment(`1-${currentMonthNumber+1}-${previousYear}`,DATE_FORMATS.DATE_MONTH_FULLYEAR).daysInMonth();

    let counter=1;

    for(let dayNumber=0;dayNumber<TOTAL_DAYS_IN_MONTH;dayNumber++){

        if(dayNumber<startDay || counter>totalDays){
            dateData[dayNumber]={
                value : ""
            };
        }
        else{
            const currentSetDate = `${currentMonthNumber+1}-${counter}-${moment().subtract(currentYearNumber, 'year').format("YYYY")}`;
            dateData[dayNumber]={
                value:counter++,
                date : currentSetDate,
                onClick : ()=> handleUpdateStartDate(currentSetDate)
            }
        }
    }

    return dateData
}

const getRespectiveDateData = ({
        startDateNumber,
        startMonthNumber,
        endDateNumber,
        endMonthNumber,
        currentMonthNumber,
        dayNumber,
        dateData
    })=>{
    if(startMonthNumber==currentMonthNumber && endMonthNumber==currentMonthNumber){
        if(dateData[dayNumber].value!="" && dateData[dayNumber].value>startDateNumber && dateData[dayNumber].value<endDateNumber){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.inRangeDateClass,
            }
        }
        else if(dateData[dayNumber].value!="" && dateData[dayNumber].value==startDateNumber && dateData[dayNumber].value==endDateNumber){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.dateCircularTypo,
            }
        }
        else if(dateData[dayNumber].value!="" && dateData[dayNumber].value==startDateNumber){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.dateLeftCornerClass,
            }
        }
        else if(dateData[dayNumber].value!="" && dateData[dayNumber].value==endDateNumber){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.dateRightCornerClass,
            }
        }
    }
    else if(startMonthNumber == currentMonthNumber){
        if(dateData[dayNumber].value!="" && dateData[dayNumber].value>startDateNumber && dateData[dayNumber].value<32){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.inRangeDateClass,
            }
        }
        else if(dateData[dayNumber].value!="" && dateData[dayNumber].value==startDateNumber && dateData[dayNumber].value==endDateNumber){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.dateCircularTypo,
            }
        }
        else if(dateData[dayNumber].value!="" && (dateData[dayNumber].value==startDateNumber )){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.dateLeftCornerClass,
            }
        }

    }
    else if(endMonthNumber == currentMonthNumber){
        if(dateData[dayNumber].value!="" && dateData[dayNumber].value>0 && dateData[dayNumber].value<endDateNumber){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.inRangeDateClass,
            }
        }
        else if(dateData[dayNumber].value!="" && (dateData[dayNumber].value==endDateNumber )){
            dateData[dayNumber]={
                ...dateData[dayNumber],
                className : styles.dateRightCornerClass,
            }
        }
    }    
}

const getStartDateFormattedData = (dateData, startDate, endDate, monthBack)=>{
    let activeMonth = moment().month();
    let currentMonthNumber = monthBack>activeMonth ? (12-(monthBack%12)-activeMonth)%12  : moment().month()-monthBack%12;

    let startMonthNumber = moment(startDate, DATE_FORMATS.MONTH_DAY_YEAR).month();
    let endMonthNumber = moment(endDate, DATE_FORMATS.MONTH_DAY_YEAR).month();

    let startDateNumber = moment(startDate, DATE_FORMATS.MONTH_DAY_YEAR).date();
    let endDateNumber = moment(endDate, DATE_FORMATS.MONTH_DAY_YEAR).date();

    if(startMonthNumber != currentMonthNumber && endMonthNumber !=currentMonthNumber ){
        return dateData;
    }

    for(let dayNumber=0;dayNumber<TOTAL_DAYS_IN_MONTH;dayNumber++){
        getRespectiveDateData({
            startDate,
            endDate,
            startDateNumber,
            startMonthNumber,
            endDateNumber,
            activeMonth,
            currentMonthNumber,
            dayNumber,
            monthBack,
            dateData,
            endMonthNumber
        })

    }

    return dateData;
}

const getDateMappedToClass = (data, numberOfDays, minDate="", maxDate="")=>{
    return map(data,(value)=>{
        const {date, value: dateValue, className} = value;
        const mappedDate = moment(date,"MM-DD-YYYY").valueOf();
        const latestTime = numberOfDays>1 ? moment().subtract(numberOfDays-1,'d').valueOf() : moment().valueOf();
        
        if(minDate.length>0){
            const currentDate = moment(minDate,"MM-DD-YYYY").valueOf();
            if(mappedDate<currentDate){
                return {
                    className : className ? className : styles.disabledDateTypo,
                    date,
                    value: dateValue
                }    
            }
    
            return {
                className : styles.dateTextTypo,
                ...value
            }
        }
        else if(maxDate.length >0){
            const currentDate = moment(maxDate,"MM-DD-YYYY").valueOf();
            if(mappedDate>currentDate){
                return {
                    className : className ? className : styles.disabledDateTypo,
                    date,
                    value: dateValue
                }    
            }
    
            return {
                className : styles.dateTextTypo,
                ...value
            }
        }

        if(mappedDate>latestTime){
            return {
                className : className ? className : styles.disabledDateTypo,
                date,
                value: dateValue
            }    
        }

        return {
            className : styles.dateTextTypo,
            ...value
        }
    })
}

export {
    getWeeklyClassMapping,
    getDateFormattedData,
    getDateMappedToClass,
    getStartDateFormattedData
}