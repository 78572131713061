import React, { useCallback, useEffect, useState } from "react";
import { Switch, Tooltip } from "antd";

import basicExclamation from "./../../../../../../../../../assets/images/exclamation/basicExclamation.svg";

import { getTooltip } from "./clinicalNotesTab.component";
import styles from "./clinicalNotesTab.module.css";

const ClinicalNotesTab = ({
        label ="",
        activeStateProps = {},
        isRequired = false,
        tooltipProps = {}
    })=>{

    const [isActive, setIsActive] = useState(isRequired);

    useEffect(()=>{
        setIsActive(isRequired);
    },[isRequired])

    const handleSwitchToggle = useCallback(()=>{
        if(isRequired){
            return;
        }
        setIsActive(!isActive);
    },[isActive, setIsActive, isRequired]);

    const { Component = ()=><></> , bodyProps = {}, name="" } = activeStateProps;
    const { handleFieldChange = () =>{} } = bodyProps;

    const handleClinicalNotesSectionDataUpdate = ({
        label
    })=>(value) =>{
        handleFieldChange({label : name})({
            [label] : value
        })
    }

    return (
        <div>
            <div className={styles.container}>
                <Switch
                    value={isActive}
                    style={{ backgroundColor: isActive ? "#C0BAC7" : "#F1EFF3" }}
                    onChange={handleSwitchToggle}
                />
                <div className={styles.innerContainer}>
                    <span className={styles.label}>
                        {label}
                    </span>
                    <Tooltip title={getTooltip(tooltipProps)}>
                        <img src={basicExclamation} className={styles.exclamationIcon} />
                    </Tooltip>
                </div>
            </div>
            { isActive && 
                <Component
                    {...bodyProps}
                    handleFieldChange={handleClinicalNotesSectionDataUpdate}
                />
            }
        </div>
    )
}

export default ClinicalNotesTab;