
const LOCAL_STORAGE_KEYS ={
    AUTHORIZATION : "Authorization",
    USER_ROLE : "UserRole"
}

const USER_ROLES = {
    SUPER_ADMIN : "SUPER_ADMIN"
}

export {
    LOCAL_STORAGE_KEYS,
    USER_ROLES
}