import moment from "moment";
import { DATE_FORMATS } from "../../../../../../constants/dateFormats";

const getRespectiveStartDate = ({startDate="", numberOfDays=6})=>{
    const endDate = moment(startDate,DATE_FORMATS.MONTH_DAY_YEAR).add(numberOfDays,'days').format(DATE_FORMATS.MONTH_DAY_YEAR);
    return {
        endDate,
        startDate
    }
}

export {
    getRespectiveStartDate
}