import React, { useCallback, useState } from "react";
import ReactQuill from 'react-quill';
import cx from "classnames";

import 'react-quill/dist/quill.snow.css';
import './textEditor.css';
import styles from "./richTextEditor.module.css";

const RichTextEditor = ({
        containerClass="",
        placeHolder="",
        ...rest
    })=>{

    const { handleFieldChange=()=>{}, name ="", value="" } = rest || {};

    const [editorText, setEditorText] = useState(value);

    const handleFieldUpdate = useCallback((value)=>{
        setEditorText(value);
        handleFieldChange({label : name})(value)
    },[setEditorText,handleFieldChange,name]);

    return (
        <div className={cx(styles.container, containerClass)}>
            <ReactQuill 
                {...rest}
                value={editorText}
                onChange={handleFieldUpdate}
                placeholder={placeHolder}
            />
        </div>
    )
}

export default RichTextEditor;