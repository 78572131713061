import { API_METHOD_TYPES, BASE_URL } from "../constants/api"
import { LOCAL_STORAGE_KEYS } from "../constants/keyConstants"

const getFetchApi = async({
        path,
        url=BASE_URL,
        body={},
        headers={},
        method=API_METHOD_TYPES.GET
    })=>{

    const bodyData = method==API_METHOD_TYPES.GET ? {} : { body : JSON.stringify(body) };

    const request = await fetch(`${url}/${path}`,{
        method,
        headers:{
            'content-type':"application/json",
            'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            ...headers
        },
        ...bodyData,
    })
    return request;
}

const getCompletedResponse = async({
        ...content
    })=>{
    
    const responseObject = await getFetchApi(content);
    
    const responseData = await responseObject.json();


    return {
        responseData,
        ...responseObject
    }
}

export {
    getFetchApi,
    getCompletedResponse
}