import moment from "moment-timezone";

import { ERROR_STATUS } from "../../constants/errorStatus"
import CustomPasswordInput from "../../molecules/CustomPasswordInput";
import Multiselect from "../../molecules/Multiselect";
import BasicDropdown from "../../molecules/baseComponents/dropDowns/basicDropdown/BasicDropdown";
import CustomBasicDropdown from "../../molecules/baseComponents/dropDowns/customBasicDropdown/CustomBasicDropdown";
import { USER_SETTING_PROFILE } from "./userSetting.constants"
import MultiSelect from "../../molecules/Multiselect/MultiSelect";
import InputWithSuffix from "../../molecules/InputWithSuffix";

import { spaceRemovalKeyDownEvent } from "../../helpers/TextManipulations.helpers";

import styles from './userSetting.module.css';

const getCorrespondingProps = (name)=>{
    switch(name){
        case "speciality":
            return {
                CustomInput : Multiselect,
                options:[
                    {id:1,value: "Certified Nurse-Midwife (CNMs)"},
                    {id:2,value: "Endocrinology"},
                    {id:3,value: "Family Practice"},
                    {id:4,value: "Obstetrics And gynaecology (OB/GYN)"},
                    {id:5,value: "Women's Health Nurse Practitioner (WHMP)"},
                    {id:6,value: "Other"}
                ],
            };
        case "credentials":
            return {
                CustomInput : Multiselect,
                options:[
                    {id:1,value:"CDCES"},
                    {id:2,value:"CNM"},
                    {id:3,value:"CPM"},
                    {id:4,value:"DO"},
                    {id:5,value:"MD"},
                    {id:6,value:"APN"}
                ],
            };
        case 'gender':
            return {
                CustomInput : BasicDropdown,
                metricTypes : [{id:1,value:"male",label:"Male"},{id:2,value:"female",label:"Female"},{id:2,value:"non-binary",label:"Non-Binary"}],
                selectClassname: styles.selectClass,
                componentContainer : styles.componentContainer
            }
        case 'timezoneOffsetHours':
            return {
                CustomInput : MultiSelect,
                isMulti:false,
                options : [
                    {id:1,label:"Denver (GMT-6)", value: "Denver (GMT-6)" },
                    {id:2,label:"Chicago (GMT-5)", value: "Chicago (GMT-5)" },
                    {id:3,label:"Phoenix (GMT-7)", value: "Phoenix (GMT-7)" },
                    {id:4,label:"Los Angeles (GMT-7)", value: "Los Angeles (GMT-7)" },
                    {id:5,label:"Anchorage (GMT-8)", value: "Anchorage (GMT-8)"},
                    {id:6,label:"Honolulu (GMT-10)", value: "Honolulu (GMT-10)"},
                    {id:6,label:"Kolkata (India)", value: "Kolkata (India)"}
                ],
                componentContainer: styles.customBasicDropdown,
                innerPrimaryContainer: styles.innerPrimaryContainer,
                selectClassname:styles.selectTimeZoneClass
            }
        case 'workEmail':
            return {
                onKeyDown : spaceRemovalKeyDownEvent
            }
        case 'middleName':
            return {
                errorTypes : [],
            }
        case "phone" :
            return {
                CustomInput: InputWithSuffix,
                countryCodeLabel : 'phoneCode',
                codeCombined : false
            }
        default: return {}
    }
}

const getModalField = ({name}) =>{
    return [{
        name,
        label: USER_SETTING_PROFILE[name],
        errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
        fieldType : "text",
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        PlaceHolder: "Enter your details",
        onBlur:()=>{},
        ...getCorrespondingProps(name)
    }]
}

const CHANGE_PASSWORD_FIELDS = [
    {
        name:"currentPassword",
        label: "Current Password",
        errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
        fieldType : "password",
        CustomInput : CustomPasswordInput,
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        PlaceHolder: "Enter your current password",
        onBlur:()=>{},
        onKeyDown : spaceRemovalKeyDownEvent
    },
    {
        name:"newPassword",
        label: "New Password",
        errorTypes : [{type: ERROR_STATUS.IS_REQUIRED}],
        fieldType : "password",
        CustomInput : CustomPasswordInput,
        inputFieldRow: styles.inputFieldRow,
        errorProps: {
            className: styles.errorClassName
        },
        className : styles.commonFieldContainer,
        fieldClassname: styles.passwordInputField,
        PlaceHolder: "Enter your new password",
        onBlur:()=>{},
        onKeyDown : spaceRemovalKeyDownEvent
    }
];

export {
    getModalField,
    CHANGE_PASSWORD_FIELDS,
}