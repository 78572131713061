import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import cx from "classnames";

import dropDown from "./../../../../../../../assets/images/dropDownBalancedPlate.svg";
import SingleDateFilter from "../../../../../../molecules/SingleDateFilter";

import { NOTES_TYPES } from "../../notesFormPopup.constants";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./secondaryHeader.module.css";

const SecondaryHeader = ({
        value,
        handleFormValuesUpdate,
        updateNoteType=()=>{},
        currentNoteType="",
    }) =>{

    const [currentDate , setCurrentDate ] = useState(value || new Date());
    const [displayPopup, setDisplayPopup ] = useState(false);

    useEffect(()=>{
        handleFormValuesUpdate({"dateOfReview":value || new Date()})
    },[]);

    const handleDisplayPopupToggle = useCallback(()=>{
        setDisplayPopup(!displayPopup)
    },[displayPopup, setDisplayPopup])
    
    const handleDateChange = (value)=>{
        setCurrentDate(value);
        handleFormValuesUpdate({"dateOfReview":value})
    }

    const handeUpdateNoteType = useCallback((newNoteType)=>()=>{
        updateNoteType(newNoteType);
    },[updateNoteType]);

    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <div className={styles.mainTypography}> Date of Review</div>
                <div className={styles.dateContainer}>
                    <div className={styles.dateContent}>
                        {moment(currentDate).format('ddd, MMM DD, YYYY')}
                        {
                            displayPopup && 
                            <SingleDateFilter
                                handleToggleCalenderDisplay={handleDisplayPopupToggle}
                                className={styles.calendarClass}
                                startDate={moment(currentDate).format("MM-DD-YYYY")}
                                numberOfDays={0}
                                handleDateUpdateAction={handleDateChange}
                            />
                        }
                    </div>
                    <img src={dropDown} className={styles.dropdown} onClick={handleDisplayPopupToggle}/>
                </div>
            </div>
            <div className={styles.notesTypeContainer}>
                <div className={styles.mainTypography}>Note Type</div>
                <div className={styles.notesButton}>
                    <div 
                        className={cx(styles.leftButton, styles.nonActiveOption, {[styles.activeOption]:currentNoteType==NOTES_TYPES.EXPRESS})}
                        onClick={handeUpdateNoteType(NOTES_TYPES.EXPRESS)}
                    >
                        {NOTES_TYPES.EXPRESS}
                    </div>
                    <div 
                        className={cx(styles.nonActiveOption, {[styles.activeOption]:currentNoteType==NOTES_TYPES.DETAILED})}
                        onClick={handeUpdateNoteType(NOTES_TYPES.DETAILED)}
                    >
                        {NOTES_TYPES.DETAILED}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondaryHeader;