import moment from "moment";
import { BASE_FORM_COMTENT_TYPES } from "./scheduleFollowUp.constants";

const getScheduleFormTabs = () =>{
    return [{
        name : "oneWeek",
        label : "1 Week",
        content : BASE_FORM_COMTENT_TYPES.DATE_FILTER,
        value : moment().add(7,'days').toString()
    },
    {
        name : "twoWeek",
        label : "2 Week",
        content : BASE_FORM_COMTENT_TYPES.DATE_FILTER,
        value : moment().add(14,'days').toString()
    },
    {
        name : "custom",
        label : "Custom",
        content : BASE_FORM_COMTENT_TYPES.DATE_FILTER
    },
    {
        name : "none",
        label : "None",
    }
]
}

export {
    getScheduleFormTabs
}