import { isEmpty, filter, isNil } from "lodash";


const getEnableButtonStatus = (fieldErrors={},formContent=[])=>{
    let status = true;
    Object.keys(fieldErrors).map((val)=>{
        if(!isEmpty(fieldErrors[val]))
        {
            status=false;
        }
        return val;
    })

    const errorList = filter(formContent,(val)=>{
        if(isNil(val.errorTypes) || val.errorTypes.length==0)
        {
            return false;
        }
        return true
    });

    let count =0;
    errorList.forEach((val)=>{
        if(val?.name in fieldErrors)
        {
            count ++;
        }
    })

    return status && count >= errorList?.length;
}

export {getEnableButtonStatus}