import React, { useCallback, useMemo } from "react";
import {map} from "lodash";
import cx from "classnames";

import activeLeftArrow from "./../../../../../../../assets/images/arrow/active/basicLeftArrow.svg";
import activeRightArrow from "./../../../../../../../assets/images/arrow/active/basicRightArrow.svg";
import nonActiveRight from "./../../../../../../../assets/images/arrow/nonActive/nonActiveRight.svg";

import MonthCalender from "./components/MonthCalender/MonthCalender";

import { getRespectiveStartDate } from "./body.helpers";

import styles from "./body.module.css";

const Body = ({
        startDate="11-1-2024",
        handleCurrentDateUpdate = () => {},
        currentBack,
        setCurrentBack,
        numberOfMonths,
        numberOfDays,
        className="",
        minDate="",   //format MM-DD-YYYY
        maxDate = ""     //format MM-DD-YYYY
    }) => {

    const {endDate} = useMemo(()=>getRespectiveStartDate({startDate, numberOfDays}),[startDate,getRespectiveStartDate]);
    const monthsMap = new Array(numberOfMonths);

    const handlePreviousMonthAction = useCallback(()=>{
        setCurrentBack(currentBack+1);
    },[setCurrentBack, currentBack]);

    const handleNextMonthAction = useCallback(()=>{
        setCurrentBack(currentBack-1);
    },[setCurrentBack,currentBack])

    return (
        <div className={cx(styles.container,className)}>
            <div className={styles.iconContainer}>
                <img src={activeLeftArrow} className={styles.arrowIcon} onClick={handlePreviousMonthAction} />
            </div>
            {map(monthsMap,(_,index)=>{
                return (
                    <MonthCalender
                        handleUpdateStartDate={handleCurrentDateUpdate}
                        monthBack={currentBack+numberOfMonths-index-1}
                        endDate={endDate}
                        startDate={startDate}
                        numberOfDays={numberOfDays}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                )
            })}
            <div className={styles.iconContainer}>
                <img src={currentBack==0 ? nonActiveRight : activeRightArrow } className={cx(styles.arrowIcon,{[styles.nonActive]: currentBack==0})} onClick={handleNextMonthAction}/>
            </div>
        </div>
    )
}

export default Body;