import React, { useEffect } from "react";
import {map} from "lodash";
import momentTimezone from "moment-timezone";

import LeftContainer from "./containers/LeftContainer/LeftContainer";
import RightContainer from "./containers/RightContainer/RightContainer";
import DefaultSpinner from "./../../atoms/spinner";

import withActionState from "../../hooks/withActionState";
import { SIDE_TAB_OPTIONS, TAB_OPTION_MAPPING } from "./userSetting.constants";
import actions from "./userSettings.action";
import actionTypes from "./UserSetting.actionTypes";

import styles from "./userSetting.module.css";
import { getFormBodyProps, getFormHeaderProps, getModalFormProps, getPatientDetailsFormattedData, getUserDetailsFormatting } from "./userSettings.helper";
import ChangePasswordModal from "../changePasswordModal";
import { getModalField } from "./userSetting.fields";

const UserSetting = ({
        dispatch,
        currentTabOption="Profile",
        userDetails,
        displayModal,
        isLoading,
        modalContentType
    }) =>{

    useEffect(()=>{
        dispatch({actionType : actionTypes.INIT_ACTION})
    },[]);

    const headerProps ={
        Security:{
            heading : "Security",
            primaryText : "Settings and recommendations to help you keep your account secure"
        },
        Profile:{
            heading : "Profile",
            primaryText : "Settings and recommendations to help you keep your account secure"
        },
        "Practice Detail":{
            heading : "Practice Detail",
            primaryText : "Settings and recommendations to help you keep your account secure"
        }
    }

    const handleOptionClick = (payload)=>{
        dispatch({actionType : actionTypes.HANDLE_OPTION_CHANGE, payload})
    }

    const handleModalToggleAction = (payload)=>{
        dispatch({actionType : actionTypes.DISPLAY_MODAL, payload});
    }

    const handleModalFormSubmitAction = (payload)=>{
        dispatch({actionType : actionTypes.CHANGE_PASSWORD, payload});
    }

    const handleModalInfoChangeAction = (payload) =>{
        dispatch({actionType : actionTypes.CHANGE_INFORMATION, payload});
    }

    const modalFormProps = getModalFormProps({modalContentType, getFormSubmitAction : modalContentType =="password" ? handleModalFormSubmitAction : handleModalInfoChangeAction });

    const formBodyProps = getFormBodyProps({modalContentType,formProps: modalFormProps, userDetails: getUserDetailsFormatting(userDetails)})

    const formHeaderProps = getFormHeaderProps({modalContentType});

    return (
        <div className={styles.container}>
            {isLoading  && <DefaultSpinner className={styles.spinnerClass} />}
            {displayModal && <ChangePasswordModal headerProps={formHeaderProps} bodyProps={formBodyProps} toggleModal={handleModalToggleAction} formSubmitAction={handleModalFormSubmitAction} />}
            { map(SIDE_TAB_OPTIONS(handleOptionClick),(option)=> <LeftContainer currentTabOption={currentTabOption}  {...option}/>) }
            <RightContainer tabClickAction={handleModalToggleAction} headerProps={headerProps[currentTabOption]} options={getPatientDetailsFormattedData(TAB_OPTION_MAPPING[currentTabOption],getUserDetailsFormatting(userDetails))} />
        </div>
    )
}

export default withActionState({Component : UserSetting, action :actions});