import SingleSelect from "../SingleSelect"
import { BASE_FORM_COMTENT_TYPES } from "./scheduleFollowUp.constants"

const getRespectiveTabComponent = (tab=[], activeTab)=>{
    const activeContent = tab.filter((val)=> val.name == activeTab)
    switch(activeContent[0].content){
        case BASE_FORM_COMTENT_TYPES.DATE_FILTER :
            return {
            Component : SingleSelect,
            componentProps :{
                isMulti: false,
                placeHolder : "Choose next follow-up date"
            }
        }            
    }
}

export {
    getRespectiveTabComponent
}