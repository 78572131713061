import React, { useCallback, useEffect, useState } from "react";

import topArrow from "./../../../../../../../../../assets/images/arrow/active/basicUpArrow.svg"
import bottomArrow from "./../../../../../../../../../assets/images/arrow/active/basicDownArrow.svg"

import styles from "./rangeSelector.module.css";

const RangeSelector = ({
        handleFieldChange=()=>{},
        name=""
    }) =>{
    
    const [timeSpent, setTimeSpent] = useState(1);

    useEffect(()=>{
        handleFieldChange({
            label : name
        })(1)
    },[]);

    const handleMoveUp = useCallback(()=>{
        if(timeSpent==1)
            return;
        setTimeSpent(timeSpent-1);
        handleFieldChange({
            label : name
        })(timeSpent-1);
    },[setTimeSpent,timeSpent,handleFieldChange]);

    const handleMoveDown = useCallback(()=>{
        if(timeSpent==30)
            return;
        setTimeSpent(timeSpent+1);
        handleFieldChange({
            label : name
        })(timeSpent+1);
    },[setTimeSpent,timeSpent,handleFieldChange])

    return (
        <div className={styles.container}>
            <span>{timeSpent} Min</span>
            <div className={styles.arrowContainer}>
                <img src={topArrow} className={styles.arrowIcon} onClick={handleMoveDown}/>
                <img src={bottomArrow} className={styles.arrowIcon} onClick={handleMoveUp}/>
            </div>
        </div>
    )
}

export default RangeSelector;