const DURATION_CHANGE_TYPES = {
    DAILY_MONITORING_LOGS : "DAILY_MONITORING_LOGS",
    BASIC_LOGS : "BASIC_LOGS",
}

const NOTES_STATIC_START_DATE = "2023-01-01"

const NOTES_TYPES = {
    EXPRESS : "Express",
    DETAILED : "Detailed"
}

const FOLLOW_UP_SECTION = {
    toProvider : "toProvider",
    nonClinicalNotes : "nonClinicalNotes"
}

const CARE_PLAN_DETAILS = {
    carePlanSummary : "carePlanSummary"
}

const NOTES_DISPLAY_STATUS = {
    DISPLAY : "display",
    HIDDEN : "hidden",
    PARTIAL_DISPLAY : 'partialDisplay'
}

export {
    DURATION_CHANGE_TYPES,
    NOTES_DISPLAY_STATUS,
    NOTES_TYPES,
    NOTES_STATIC_START_DATE,
    FOLLOW_UP_SECTION,
    CARE_PLAN_DETAILS
}