import React, {useState, useEffect } from "react";
import {map} from "lodash";

import FormField from "./formField";
import styles from "./formContent.module.css";

const FormContent = ({
        formContent,
        formState = {},
        handleFieldChange = ()=>{},
        formBodyClassname="",
        ...rest
    }) =>{

    const { fieldError = {} } = rest;

    return (
        <div className={`${styles.cardFormBody} ${formBodyClassname}`}>
            {map(formContent,(fieldProps)=>{
                return (
                <React.Fragment key={fieldProps?.name}>
                    <FormField 
                        value={formState[fieldProps?.name]}
                        handleFieldChange={handleFieldChange}
                        formState={formState}
                        {...rest}
                        {...fieldProps}
                        fieldError={fieldError[fieldProps?.name]}
                    />
                </React.Fragment>
                )
            })}
        </div>
    )
}

export default FormContent;