import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "./component/Header";
import Body from "./component/Body";

import { getRespectiveFormData } from "./notesFormPopup.helpers";
import { getNotesPopupFields } from "./notesFormPopup.fields";

import SecondaryHeader from "./component/SecondaryHeader";

import { NOTES_DISPLAY_STATUS } from "../../patientProfile.constants";
import { CARE_PRO_PLAN_STATIC_CONTENT, DEFAULT_NOTE_TYPE, NOTES_TYPES_FIELDS } from "./notesFormPopup.constants";

import styles from "./notesFormPopup.module.css";

const NotesFormPopup = ({
        displayStatus,
        handleNotesToggleStatusUpdate,
        handleNotesFormSubmitAction,
        handleNotesCloseAction,
        handleNotesFormUpdate,
        formState,
    }) =>{

    const [formValues  , setFormValues ]= useState({});

    useEffect(()=>{
        handleNotesFormUpdate({
            noteType : DEFAULT_NOTE_TYPE,
        });
        setFormValues((prev)=>{
            return {
                ...prev,
                carePlanSummary : CARE_PRO_PLAN_STATIC_CONTENT 
            }
        })
    },[]);

    const handleFormValuesUpdate = useCallback((formValues)=>{
        setFormValues((prev)=>{
            return {
                ...prev,
                ...formValues
            }
        })
    },[handleNotesFormUpdate]);

    const handleUpdateNoteType = useCallback((updatedNoteType)=>{
        handleNotesFormUpdate({
            noteType : updatedNoteType,
            carePlanSummary : CARE_PRO_PLAN_STATIC_CONTENT 
        });
    },[]);

    const handleFormSubmit = (payload={})=>{
        handleNotesFormSubmitAction({...formState,...formValues,...payload })
    }

    const { noteType = DEFAULT_NOTE_TYPE } = formState || {};

    const formProps = useMemo(()=>{
        return getRespectiveFormData(
            getNotesPopupFields(NOTES_TYPES_FIELDS[ noteType || DEFAULT_NOTE_TYPE] || []),
            handleFormSubmit,
            handleFormValuesUpdate,
            noteType
        )
    },[handleFormSubmit, handleFormValuesUpdate,noteType,getRespectiveFormData,getNotesPopupFields]);

    const handleNotesPartialDisplay = (event)=>{
        event?.stopPropagation();
        if( displayStatus === NOTES_DISPLAY_STATUS.DISPLAY){
            handleNotesToggleStatusUpdate({status : NOTES_DISPLAY_STATUS.PARTIAL_DISPLAY});
            return;            
        }
        handleNotesToggleStatusUpdate({ status : NOTES_DISPLAY_STATUS.DISPLAY});
    }

    return (
        <div className={styles.container}>
            <Header 
                displayStatus={displayStatus} 
                handleNotesPartialDisplay={handleNotesPartialDisplay} 
                handleNotesDisplayClose={handleNotesCloseAction} 
            />
            {displayStatus == NOTES_DISPLAY_STATUS.DISPLAY && 
                <SecondaryHeader 
                    updateNoteType={handleUpdateNoteType} 
                    handleFormValuesUpdate={handleFormValuesUpdate}
                    currentNoteType={noteType}
                />}
            {displayStatus == NOTES_DISPLAY_STATUS.DISPLAY && 
                <Body 
                    formProps={formProps} 
                    formState={formValues}
                    noteType={noteType}
                    handleFormValuesUpdate={handleFormValuesUpdate}
                    handleFormSubmit={handleFormSubmit}
                />}
        </div>
    )
}

export default NotesFormPopup;