import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import moment from "moment";

import CalenderBody from "./../DateFilter/component/DateCalender/components/Body";

import styles from "./singleDateFilter.module.css";

const SingleDateFilter = ({
        startDate,
        handleDateUpdateAction = ()=>{},
        handleToggleCalenderDisplay,
        numberOfMonths=1,
        numberOfDays,
        className="",
        ...rest
    })=>{

    const [currentBack, setCurrentBack] = useState(0);

    useEffect(()=>{
        document.addEventListener("mousedown",handleCloseDisplayCalender);
    
        return () => {
          document.removeEventListener("mousedown", handleCloseDisplayCalender);
        };
    },[]);

    const handleCloseDisplayCalender = (event)=>{
        event.stopPropagation();
        if (containerDiv.current && !containerDiv.current.contains(event.target)) {
            handleToggleCalenderDisplay();
        }
    }

    const containerDiv = useRef();

    const handleUpdateDateToCurrentDateAction = useCallback((updatedDate)=>{
        handleDateUpdateAction(updatedDate);
        handleToggleCalenderDisplay();
    },[handleDateUpdateAction, handleToggleCalenderDisplay]);

    return (
        <div ref={containerDiv} className={cx(styles.container, className)}>
            <CalenderBody
                startDate={moment(startDate).format("MM-DD-YYYY")}
                currentBack={currentBack}
                setCurrentBack={setCurrentBack}
                handleCurrentDateUpdate={handleUpdateDateToCurrentDateAction}
                numberOfMonths={numberOfMonths}
                numberOfDays={numberOfDays}
                className={styles.bodyContainer}
                {...rest}
            />
        </div>
    )
}

export default SingleDateFilter