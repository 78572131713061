import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';

import { progressMonitoringType } from '../../../../../../../store/progressMonitoringAtoms/progressMonitoringType';
import WeeklyFilter from '../WeeklyFilter';
import { getWeeklyFormattedWeeklyDates } from '../../../../../../helpers/dateFormatter';
import DateFilter from '../../../../../../molecules/DateFilter';

import DeviceCard from '../deviceCard';

import styles from './header.module.css';

export default function Header({
    progressMonitoringType: incomingProgressMonitoringType,
    handleProgressMonitoringDurationChange,
    filterDuration,
    deviceDetails,
    handleDeviceListDrawerToggle
  }) {

  const [monitoringType,setMonitoringType]=useRecoilState(progressMonitoringType);

  useEffect(()=>{
    setMonitoringType(incomingProgressMonitoringType);
  },[])

  const handleFilterDurationChange = useCallback((updatedStartDate)=>{
    handleProgressMonitoringDurationChange({ updatedStartDate } )
  },[handleProgressMonitoringDurationChange]);

  return (
    <div className={styles.container}>
      <div className={styles.headingMain}>
        <div className={styles.leftContainer}>
          <div className={styles.headingMainText}>
            Glucose
          </div>
          <DeviceCard
           deviceDetails={deviceDetails}
           handleDeviceListDrawerToggle={handleDeviceListDrawerToggle}
          />
        </div>
        <DateFilter 
          handleDateUpdateAction={handleFilterDurationChange} 
          startDate={filterDuration} 
          format={"ddd, MMM DD"} 
          endDate={moment(filterDuration,"MM-DD-YYYY").add(6,'d').format("MM-DD-YYYY")}
        />
      </div>
    </div>
  );
}
