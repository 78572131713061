import React, { useEffect, useState } from "react";

import Tabs from "./components/Tabs";
import { getScheduleFormTabs } from "./scheduleFollowUp.tabs";

import { getRespectiveTabComponent } from "./scheduleFollowUp.helpers";

import styles from "./scheduleFollowUp.module.css"
import moment from "moment";

const ScheduleFollowUp = ({
        handleValueChange=()=>()=>{},
        formState={}
    }) =>{

    const [activeTab, setActiveTab] = useState("oneWeek");
    
    useEffect(()=>{
        handleValueChange({label : "followUp"})(moment().add(7,'day').toString());
    },[])

    const handleTabChange = (name , value)=>{
        setActiveTab(name);
        handleValueChange({label : "followUp"})(value);
    }

    const tabs = getScheduleFormTabs();

    const { Component , componentProps={} } = getRespectiveTabComponent(tabs, activeTab) || {};
    
    return (
        <div className={styles.container}>
            <Tabs 
                handleTabChange={handleTabChange} 
                tabList={tabs} 
                activeTab={activeTab}
            />
            {Component && 
            <Component
                {...componentProps} 
                value={formState?.["followUp"]} 
                handleValueChange={handleValueChange} 
                name={"followUp"}
            />
            }
        </div>
    )
}

export default ScheduleFollowUp;