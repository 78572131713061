import React, { useState, useCallback } from "react";

import FormContent from "./components/formContent";
import FormHeader from "./components/formHeader";
import FormFooter from "./components/formFooter";
import withError from "../../hoc/withError";
import { getEnableButtonStatus } from "./formManager.helper";

const FormManager = ({
        formProps,
        initialState={},
        externalFormSubmitAction =()=>{},
        checkErrorState,
        fieldError
    })=>{

    const [formState,setFormState] = useState(initialState);

    const { formHeaderProps, formFooterProps, formSubmitAction = ()=>{}, handleValueChange = ()=>{}, formBodyClassname, formContent, formContainerProps } = formProps || {};

    const { containerClassname = "" } = formContainerProps || {};

    const handleFieldChange = useCallback(({label})=> (value) =>{
            setFormState((previousFormState)=>{
            handleValueChange({...previousFormState, [label]:value });
            return {...previousFormState, [label]:value }
        })
    },[setFormState])

    const formSubmitHandler = useCallback((event) =>{
        event.preventDefault();
        formSubmitAction(formState);
        externalFormSubmitAction(formState);
    },[formSubmitAction,formState,externalFormSubmitAction]);

    const enabledStatus = getEnableButtonStatus(fieldError,formContent);

    return (
        <div className={containerClassname}>
            <FormHeader {...formHeaderProps}/>
            <FormContent
                formBodyClassname={formBodyClassname}
                displayError={Object.values(formState).length > 0}
                checkErrorState={checkErrorState}
                fieldError={fieldError}
                formContent={formContent}
                formState={ (initialState && Object.keys(initialState).length>0) ? initialState : formState}
                handleFieldChange={handleFieldChange}
            />
            <FormFooter {...formFooterProps} formSubmitAction={formSubmitHandler} enabled={enabledStatus} />
        </div>
    )
}

export default withError({Component : FormManager});