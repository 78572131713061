import React , {useCallback, useEffect, useRef} from "react";

import withActionState from "../../hooks/withActionState";

import UpperContainer from "./containers/UpperContainer";
import LowerContainer from "./containers/LowerContainer";
import { PATIENT_TABS } from "./patientProfile.tabs";
import actions from "./patientProfile.actions";
import actionTypes from "./patientProfile.actionTypes";

import { getFormatterHedearOptions, getPatientDetailsFormattedData, getTabComponent, getTabProps } from "./patientProfile.helper";
import DefaultSpinner from "../../atoms/spinner/Spinner";
import { PROGRESS_MONITORING_TYPE } from "./components/progressMonitoring/progressMonitoring.constants";

import NotesFormPopup from "./components/NotesFormPopup/NotesFormPopup";
import { NOTES_DISPLAY_STATUS } from "./patientProfile.constants";
import NotesDiscardPopup from "./components/NotesFormPopup/component/NotesDiscardPopup/NotesDiscardPopup";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";

import styles from "./patientProfile.module.css"

const PatientProfile = ({
        dispatch , 
        overViewDurationTypes, 
        metricsResponse, 
        patientDetails ={}, 
        currentProgressMonitoringType=PROGRESS_MONITORING_TYPE.BLOOD_GLUCOSE, 
        recentTrends=[], 
        isLoading, 
        activeTab="Overview", 
        overviewDuration = 7,
        balancePlateValues,
        noteStatus,
        notesDiscardDisplay,
        notes,
        enableToast,
        toastProps,
        handleSetSubscriber=()=>{},
        handleSubscriberUpdate=()=>{},
        formState,
        notesData
    }) =>{

    useEffect(()=>{
        dispatch({actionType : actionTypes.INIT_ACTION});
    },[]);

    useEffect(()=>{
        handleSetSubscriber(subscribedOne);
        handleSubscriberUpdate(subscribedOne);
        window.onbeforeunload = (event)=>{
            if(noteStatus == undefined ||  noteStatus == NOTES_DISPLAY_STATUS.HIDDEN){
                return ""
            }
            event?.preventDefault();
            return false
        }

        return ()=>{
            window.onbeforeunload = null;
        }
    },[noteStatus]);

    const handleNotesFormUpdate = useCallback((payload) =>{
        dispatch({actionType : actionTypes.HANDLE_NOTES_FORM_DATA_UPDATE, payload})
    },[dispatch])

    const handleOverDurationChange = (payload)=>{
        dispatch({actionType: actionTypes.OVERVIEW_DURATION_CHANGE, payload});
    }

    const handleToggleLoaderAction = (payload) =>{
        dispatch({actionType : actionTypes.HANDLE_LOADER_TOGGLE,payload});
    }

    const handleNotesToggleStatusUpdate = (payload) =>{
        dispatch({actionType : actionTypes.HANDLE_NOTES_STATE_UPDATE, payload})
    }

    const handleNotesDisplayAction = ()=>{
        dispatch({actionType : actionTypes.HANDLE_NOTES_DISPLAY})
    }

    const handleNotesDiscardPopupToggle = (payload) =>{
        dispatch({actionType : actionTypes.HANDLE_NOTES_DISCARD_POPUP_TOGGLE, payload})
    }

    const handleNotesFormSubmitAction = useCallback((payload) =>{
        dispatch({actionType : actionTypes.NOTES_FORM_SUBMIT, payload})
    },[dispatch])

    const handleNoteDiscardAccept = () =>{
        handleNotesToggleStatusUpdate({status : NOTES_DISPLAY_STATUS.DISPLAY})
        dispatch({actionType : actionTypes.HANDLE_NOTES_DISCARD_POPUP_TOGGLE, payload :{
            notesDiscardDisplay : false,
            accept : true
        }});
    }

    const handleNotesCloseAction = (payload) =>{
        handleNotesToggleStatusUpdate({status : NOTES_DISPLAY_STATUS.HIDDEN})
        dispatch({actionType : actionTypes.HANDLE_NOTES_DISCARD_POPUP_TOGGLE, payload});
    }

    const handlePageLeaveAction = (callback)=>{
        if(noteStatus != undefined &&  noteStatus != NOTES_DISPLAY_STATUS.HIDDEN){
            handleNotesCloseAction({
                notesDiscardDisplay : true,
                callback
            })
            return ;
        }
        callback();
    }

    const handleTabChange = (payload)=>{
        dispatch({actionType: actionTypes.CHANGE_TAB_ACTION, payload});
    }

    function subscribedOne(callback){
        if(noteStatus != undefined &&  noteStatus != NOTES_DISPLAY_STATUS.HIDDEN){
            handleNotesCloseAction({
                notesDiscardDisplay : true,
                callback
            })
            return ;
        }
        callback();
    }

    const Component = getTabComponent(activeTab);

    const correspondingTabProps = getTabProps({
        balancePlateValues,
        handleNotesDisplayAction,
        overViewDurationTypes,
        handleTabChange,
        overviewDuration,
        handleOverDurationChange,
        currentProgressMonitoringType,
        activeTab
    });

    const handleToastUpdateAction = useCallback((payload)=>{
        dispatch({actionType : actionTypes.HANDLE_TOAST_UPDATE, payload})
    },[dispatch]);

    const handleInsulinStatusUpdate = useCallback((payload)=>{
        dispatch({actionType : actionTypes.UPDATE_INSULIN_STATUS, payload})
    },[])

    const handleToastCloseAction = ()=>{
        dispatch({actionType : actionTypes.HANDLE_TOAST_UPDATE, payload: {
            enableToast : false
        }})
    }

    // console.log('NOTES DATA : ', notesData.responseData)

    return (
        <div className={styles.container}>
            {isLoading && <DefaultSpinner />}
            {enableToast && <BasicToast {...toastProps} handleToastToggle={handleToastCloseAction} display={enableToast} displayIcon/>}
            { notesDiscardDisplay && 
                <NotesDiscardPopup 
                    handleDiscardNotes={handleNotesDiscardPopupToggle} 
                    handleNotesDisplayAction={handleNoteDiscardAccept}
                />
            }
            <UpperContainer 
                handlePageLeaveAction={handlePageLeaveAction} 
                options={getFormatterHedearOptions({options : patientDetails,composedOptions: ["fullName","age","edd","insulinStatus"], handleInsulinStatusUpdate})}
            />
            <LowerContainer 
              notes={notes}
              notesData={notesData}
              userName={patientDetails?.["fullName"]} 
              balancePlateValues={balancePlateValues} 
              handleToggleLoaderAction={handleToggleLoaderAction} 
              recentTrends={recentTrends} 
              correspondingTabProps={correspondingTabProps}  
              Component={Component} activeTab={activeTab} 
              handleTabChange={handleTabChange} 
              options={PATIENT_TABS} 
              patientDetails={getPatientDetailsFormattedData(patientDetails)} 
              metricsResponse={metricsResponse} 
              handleToastUpdateAction={handleToastUpdateAction}
            />
            {noteStatus && noteStatus != NOTES_DISPLAY_STATUS.HIDDEN && 
                <NotesFormPopup
                    formState={formState}
                    handleNotesFormUpdate={handleNotesFormUpdate}
                    handleNotesFormSubmitAction={handleNotesFormSubmitAction} 
                    handleNotesToggleStatusUpdate={handleNotesToggleStatusUpdate} 
                    displayStatus={noteStatus}
                    handleNotesCloseAction={handleNotesCloseAction}
                />
            }
        </div>
    )
}

export default withActionState({Component : PatientProfile, action : actions});