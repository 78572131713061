
import styles from "./notesFormPopup.module.css";

const getRespectiveFormData = (
        formField, 
        getPrimaryFormSubmit=()=>{},
        handleNotesFormUpdate=()=>{},
        noteType
    ) =>{
        switch(noteType){
            case "Express":
                return {
                    formProps:{
                        formContent : formField,
                        formFooterProps:{
                            butttonText : "Add Notes",
                            footerContainerClassname: styles.footerContainerClass ,
                            enabledClassname: styles.enabledClassname,
                            buttonClassname : styles.buttonClassname
                        },
                        formContainerProps:{
                            containerClassname : styles.formContainer
                        },
                        handleValueChange : handleNotesFormUpdate,
                        formSubmitAction : getPrimaryFormSubmit,
                    }
                }
            case "Detailed":
                return {};
            default:
                return {}            
        }
}

export {
    getRespectiveFormData
}