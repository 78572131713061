
const NOTES_FORM_FIELDS_BASE_NAMES = {
    expressNote : "expressNotes",
    timeSpent : "timeSpent",
    clinicalInterpretation:"clinicalInterpretation",
    plan : "plan",
    patientCommunication : "patientCommunication",
    followUp : "followUp",
    carePlanSummary : "carePlanSummary",
    toProvider : "toProvider",
    nonClinicalNote : "nonClinicalNote",
    billingCode : "billingCode"
}

const CARE_PRO_PLAN_STATIC_CONTENT="• Reviewed the patient’s care plan developed by Dr. Parker.\n• Verified goals to lower HbA1c from 9.8% to below 7% within six months.\n• Identified barriers to progress: inconsistent blood glucose monitoring and lack of adherence to diet recommendations."

const PLACEHOLDER_TEXTS = {
    [NOTES_FORM_FIELDS_BASE_NAMES.nonClinicalNote] : "Example: Patient shows good progress in managing glucose levels. Next steps include focusing on consistent meal timing and portion control",
    [NOTES_FORM_FIELDS_BASE_NAMES.toProvider] : "Example: Review dietary changes and monitor glucose levels, continue focusing on fiber intake and hydration.",
    [NOTES_FORM_FIELDS_BASE_NAMES.expressNote] : "Example:\n 10/12/2024          • FT: 063,          * 1HR B: 084,          • 1HR L: 134,          * 1HR D: 105"
}

const NOTES_TYPES ={
    EXPRESS : "Express",
    DETAILED : "Detailed"
}

const DEFAULT_NOTE_TYPE = NOTES_TYPES.EXPRESS

const EXPRESS_NOTES_FIELDS = [{
    name : "expressNotes",
    label : "Express Note"
}]

const NOTES_TYPES_FIELDS = {
    Express : EXPRESS_NOTES_FIELDS,
    Detailed : []
}

const NOTES_FORM_FIELDS = [{
        name : "timeSpent",
        label : "Time spent"
    },
    {
        name : "clinicalInterpretation",
        label : "Clinical Interpretation"
    },
    {
        name : "plan",
        label : "Plan (Treatment Recommendations)"
    },
    {
        name : "patientCommunication",
        label : "Patient Communication"
    },
    {
        name : "followUp",
        label : "Follow-up"
    }
]

const CARE_PLAN_DETAIL_FORM = [{
    name : "carePlanSummary",
    label : "Care Plan Summary"
}]

const PRIVATE_NOTE_FORM = [{
    name : "toProvider",
    label : "To Provider"
},
{
    name : "nonClinicalNote",
    label : "Non-clinical Note"
}
]

const BILLING_DETAILS_FORM = [{
    name : "billingCode",
    label : "Choose appropriate billing code"
}]

const BILLING_TYPES ={
    "CPT 99426, 99427 - Principal Care Management" : "CPT 99426, 99427 - Principal Care Management",
    "CPT 99457, 99458 - Remote Patient Monitoring" : "CPT 99457, 99458 - Remote Patient Monitoring"
}

export {
    BILLING_DETAILS_FORM,
    NOTES_FORM_FIELDS,
    NOTES_FORM_FIELDS_BASE_NAMES,
    NOTES_TYPES,
    DEFAULT_NOTE_TYPE,
    EXPRESS_NOTES_FIELDS,
    NOTES_TYPES_FIELDS,
    CARE_PLAN_DETAIL_FORM,
    PRIVATE_NOTE_FORM,
    BILLING_TYPES,
    PLACEHOLDER_TEXTS,
    CARE_PRO_PLAN_STATIC_CONTENT
}