import React,{useCallback, useState} from "react";
import { Form } from "react-bootstrap";
import { isNaN } from "lodash";

import styles from "./inputWithSuffix.module.css";
import { getUSFormattedPhoneNumber } from "../../helpers/TextManipulations.helpers";

const InputWithSuffix = ({
        onChange, 
        name, 
        handleFieldChange,
        errorTypes,
        checkErrorState, 
        codeCombined=true,
        countryCodeLabel="",
        formState={},
        ...rest
    }) =>{

    const { value = "" } = rest;

    const [currentValue,setCurrentValue] = useState(value);

    const [currentPrefix,setCurrentPrefix] = useState(formState[countryCodeLabel] || "+1");

    const handlePrefixChange = useCallback((event) =>{
        setCurrentPrefix(event?.target?.value);
        if(!codeCombined){
            handleFieldChange({label: countryCodeLabel})(event?.target?.value);
        }
    },[setCurrentPrefix]);

    const handleInputFieldChange = (event)=>{
        let updatedValue = "";
        for(let value of event?.target?.value){
            if(value.charCodeAt(0)>=48 && value.charCodeAt(0)<=57){
                updatedValue+=value;
            }
        }
        if(updatedValue?.length>10){
            return;
        }
        if(event.target.value[event.target.value.length-1]==")"){
            updatedValue=updatedValue.substring(0,2);
        }
        setCurrentValue(updatedValue);
        if(codeCombined){
            handleFieldChange({label:name})(currentPrefix+","+updatedValue);
        }
        else{
            handleFieldChange({label:name})(updatedValue);
            handleFieldChange({label: countryCodeLabel})(currentPrefix);
        }
        checkErrorState(currentPrefix+updatedValue, name, errorTypes);
    }

    return (
        <div className={styles.container}>
            <Form.Select className={styles.prefixContainer} onChange={handlePrefixChange}>
                <option value="+1">+1</option>
                <option value="+91">+91</option>
                <option value="+2">+2</option>
            </Form.Select>
            <input type="text" className={styles.inputField} {...rest}  value={getUSFormattedPhoneNumber(currentValue)} onChange={handleInputFieldChange}/>
        </div>
    )
}

export default InputWithSuffix;