import React, { useCallback } from "react";
import { map } from "lodash";
import cx from "classnames";

import styles from "./tabs.module.css";

const Tabs = ({
        tabList,
        handleTabChange,
        activeTab
    }) =>{
    
    const handleTabClick = useCallback((name,value)=>()=>{
        handleTabChange(name,value)
    },[handleTabChange]);

    return (
        <div className={styles.container}>
            {map(tabList,({
                label,
                name,
                value
            })=>{
                return (
                <div 
                    className={cx(styles.tabItem,{[styles.activeTab]: name == activeTab})}
                    onClick={handleTabClick(name,value)}
                >
                    {label}
                </div>)
            })}
        </div>
    )
}

Tabs.defaultProps={
    tabList : [],
    handleTabChange : ()=>{},
    activeTab : ""
}

export default Tabs;