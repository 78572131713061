import React from "react";
import {map} from "lodash";

import styles from "./clinicalNotesTab.module.css";

const getTooltip= ({
        title="",
        content = []
    })=>{
    return (
        <div className={styles.tooltipContainer}>
            <span className={styles.tooltipTitle}>
                {title}
            </span>
            <ul className={styles.tooltipContent}>
                {map(content,(contentItem)=><li>{contentItem}</li>)}
            </ul>
        </div>
    )
}

export {
    getTooltip
}