const CLINICAL_NOTES_SECTIONS = {
    MONITORING_AND_REVIEWING : "Monitoring & Reviewing Patient Data",
    CARE_PLAN_IMPLEMENTATION : "Care Plan Implementation and Monitoring",
    COMMUTE_WITH_PATIENT : "Communication with the Patient",
    MEDICATION_MANAGEMENT : "Medication Management",
    CARE_COORDINATION : "Care Coordination",
    PREVENTIVE_AND_EDUCATIONAL_SUPPORT : "Preventive and Educational Support",
}

const CLINICAL_NOTES_SECTIONS_NAMES = {
    MONITORING_AND_REVIEWING : "MONITORING_AND_REVIEWING",
    CARE_PLAN_IMPLEMENTATION : "CARE_PLAN_IMPLEMENTATION",
    COMMUTE_WITH_PATIENT : "COMMUTE_WITH_PATIENT",
    MEDICATION_MANAGEMENT : "MEDICATION_MANAGEMENT",
    CARE_COORDINATION : "CARE_COORDINATION",
    PREVENTIVE_AND_EDUCATIONAL_SUPPORT : "PREVENTIVE_AND_EDUCATIONAL_SUPPORT",
}

const PLACEHOLDER_TEXTS = {
    DETAILS :{
        CARE_PLAN_IMPLEMENTATION : "Example: Educated the patient about the importance of logging blood glucose levels daily",
        COMMUTE_WITH_PATIENT : "Example: Educated the patient about the importance of logging blood glucose levels daily",
        MEDICATION_MANAGEMENT : "Example: Educated the patient about the importance of logging blood glucose levels daily",
        CARE_COORDINATION : "Example: Educated the patient about the importance of logging blood glucose levels daily",
        PREVENTIVE_AND_EDUCATIONAL_SUPPORT : "Example: Educated the patient about the importance of logging blood glucose levels daily",
    },
    REFERENCE_DATA : {
        MONITORING_AND_REVIEWING : "Example: Review dietary changes and monitor glucose levels, continue focusing on fiber intake and hydration.",
    },
    CLINICAL_INTERPRETATION  : {
        MONITORING_AND_REVIEWING : "Example: Review dietary changes and monitor glucose levels, continue focusing on fiber intake and hydration.",
    },
    ACTION_PLANS: {
        MONITORING_AND_REVIEWING : "Example: Review dietary changes and monitor glucose levels, continue focusing on fiber intake and hydration."
    }
}

const BASE_FIELDS_NAMES ={
    TIME_SPENT : "TIME_SPENT",
    DETAILS : "DETAILS",
    REFERENCE_DATA : "REFERENCE_DATA",
    CLINICAL_INTERPRETATION : "CLINICAL_INTERPRETATION",
    ACTION_PLANS : "ACTION_PLANS"
}

const BASE_FORM = [
    {
        type :"TIME_SPENT",
        label :"Time Spent",
        name : "timeSpent"
    },
    {
        type : "DETAILS",
        label :"Details",
        name : "details"
    }
]

const MONITORING_AND_REVIEWING_FORM = [
    {
        type :"TIME_SPENT",
        label :"Time Spent",
        name : "timeSpent"
    },
    {
        type : BASE_FIELDS_NAMES.REFERENCE_DATA,
        label :"Reference Data",
        name : "referenceData"
    },
    {
        type : BASE_FIELDS_NAMES.CLINICAL_INTERPRETATION,
        label :"Clinical Interpretation",
        name : "clinicalInterpretation"
    },
    {
        type : BASE_FIELDS_NAMES.ACTION_PLANS,
        label :"Action Plans",
        name : "actionPlans"
    }
]

const TOOLTIP_PROPS={
    MONITORING_AND_REVIEWING :{
        title : "What is considers as Monitoring and Reviewing Patient Data?",
        content : ["Reviewing physiological data transmitted by remote monitoring devices (e.g., weight, blood pressure, blood glucose, oxygen saturation)","Identifying trends or anomalies in the patient's data","Reporting critical changes to the supervising provider"]
    },
    CARE_PLAN_IMPLEMENTATION : {
        title : "What is considers as Care Plan Implementation and Monitoring?",
        content : ["Assisting in the execution of a care plan developed by the physician/QHP","Monitoring the patient’s progress toward treatment goals"]
    },
    COMMUTE_WITH_PATIENT : {
        title : "What is considers as Communication with the Patient?",
        content : ["Answering questions about the chronic condition","Providing guidance and support for self-management"]
    },
    MEDICATION_MANAGEMENT : {
        title : "What is considers as Medication Management?",
        content : ["Assisting with medication adherence","Educating patients or caregivers about medication use, side effects, and interactions","Documenting and reporting any medication issues to the supervising provider"]
    },
    CARE_COORDINATION :{
        title : "What is considers as Care Coordination?",
        content : ["Communicating with other healthcare providers about the patient's progress or changes in their condition","Arranging follow-up specialist appointments or diagnostic tests as needed"]
    },
    PREVENTIVE_AND_EDUCATIONAL_SUPPORT : {
        title : "What is considers as Preventive and Educational Support?",
        content : ["Educating patients about lifestyle changes and preventive measures (e.g., smoking cessation, diet)","Supporting patients in setting and achieving manageable goals for their chronic condition"]
    }
}

export {
    CLINICAL_NOTES_SECTIONS,
    BASE_FIELDS_NAMES,
    BASE_FORM,
    MONITORING_AND_REVIEWING_FORM,
    TOOLTIP_PROPS,
    PLACEHOLDER_TEXTS,
    CLINICAL_NOTES_SECTIONS_NAMES
}