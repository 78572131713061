import React, { useCallback } from "react";
import {map} from "lodash";

import styles from "./detailedForm.module.css";

const DetailedForm = ({
        subForms=[],
        initialState = {},
        handleFormSubmit = ()=>{}
    })=>{
    
    const handleDetailedNotesUpdate = useCallback((event)=>{
        event.preventDefault();
        handleFormSubmit();
    },[handleFormSubmit]);

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                {map(subForms,(subFormValues)=>{
                    const { MainComponent, ...rest } = subFormValues;
                    return <MainComponent {...rest} formState={initialState} />
                })}
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.notesButton} onClick={handleDetailedNotesUpdate}>
                    Add To Notes
                </div>
            </div>
        </div>
    )
}

export default DetailedForm