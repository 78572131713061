import React, { useState } from "react";
import moment from "moment";

import notesIcon from "./../../../../../../../../../../../assets/images/notes/notes.svg";

import styles from "./notesCard.module.css";
import NotesCardPopup from "../NotesCardPopup";
import { getNotesToPopupContentFormat } from "./notesCard.helper";
import { NOTES_TYPES } from "../../../../Notes.constants";

const NotesCard =(props) =>{

    const [notesPopupDisplay, setNotesPopupDisplay] = useState( false);

    const { reviewDate="", expressNotes="", careProUserFullName="No Data", noteType=""} = props;

    const handleNotesDisplayOpen = () =>{
        setNotesPopupDisplay(true);
    }

    const handleNotesDisplayClose = ()=>{
        setNotesPopupDisplay(false);
    }

    return (
        <>
            {notesPopupDisplay && 
                <NotesCardPopup 
                    handleNotesDisplayClose={handleNotesDisplayClose} 
                    notes={getNotesToPopupContentFormat(props)} 
                    date={moment(reviewDate || "").format('ddd, MMM DD')}
                    userName={careProUserFullName}
                />}
            <div className={styles.container} onClick={handleNotesDisplayOpen} >
                <img src={notesIcon} className={styles.notesIcon} />
                <div className={styles.mainOuterContainer}>
                    <div className={styles.headerTopContainer}>
                        <div className={styles.headingText}>
                            {moment(reviewDate).format('ddd, MMM DD,YYYY')}
                        </div>
                        <div className={styles.notesTypeContainer}>
                            {NOTES_TYPES[noteType]}
                        </div> 
                    </div>
                    <div className={styles.secondaryText}>
                        {expressNotes}
                    </div>
                    <div className={styles.tertiaryText}>
                        <div className={styles.submitttedByLabel}>
                            Submitted By
                        </div>
                        <div className={styles.submittedByContent}>
                            {careProUserFullName}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotesCard