
import styles from "./tabs.module.css"
import { useState } from "react";
import Overview from "../overview/Overview";
const Tabs = ({memberDetail}) => {

    const [isClicked, setIsClicked] = useState("Overview");

    const handleClick = (currentValue) => {
        setIsClicked(currentValue);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabOptions}>
                <div className={isClicked == "Overview" ? styles.tabActive : styles.tab}
                    onClick={() => handleClick("Overview")}>Overview</div>
            </div>
            {isClicked==="Overview" && <Overview memberDetail={memberDetail}></Overview>}
        </div>


    )
}

export default Tabs;
