import React from "react";

import styles from "./subFormWrapper.module.css";

const SubFormWrapper = ({
        label,
        Component,
        bodyProps={},
        handleValueChange = () => {},
        formState={}
    })=>{
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.headerContentTypo}>
                    {label}
                </span>
            </div>
            <Component
                handleValueChange={handleValueChange}
                formState={formState}
                {...bodyProps}
            />
        </div>
    )
}

export default SubFormWrapper