import React, { useEffect, useRef, useState } from "react";
import {map} from "lodash";
import cx from "classnames";

import SearchIcon from "./../../../assets/images/search-icon.svg";
import DropDownIcon  from "./../../../assets/images/dropdown_arrow.svg";

import styles from "./multiSelect.module.css";

const MultiSelect = ({
    options = [],
    isMulti = true,
    placeholder="Choose your speciality",
    checkErrorState=()=>{},
    errorTypes,
    dropdownContainer="",
    CustomDropDownComponent,
    isDisabled=false,
    inputContainerClass="",
    ...rest}) =>{
    const inputRef = useRef();

    const { handleFieldChange=()=>()=>{}, name, value = [] } = rest;

    const [fieldOptions,setFieldOptions] = useState(options);

    const [fieldSize, setFieldSize] = useState(50);

    const [popupWidth, setPopupWidth] = useState(10);

    const [selectedValues,setSelectedValues] = useState(value);

    const [isFieldFocused,setIsFieldFocused] = useState(false);

    useEffect(()=>{
        setPopupWidth(inputRef?.current?.offsetWidth);
    },[]);

    const divRef = useRef(null);

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsFieldFocused(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
    };
    }, []);

    useEffect(()=>{
        setFieldOptions(options)
    },[options])

    const handleFieldResize = () =>{
        setFieldSize(inputRef.current.offsetHeight);
    }
    
    const handleListUpdate = (event)=>{
        setFieldOptions((prev)=>{
            let list = options?.filter((val)=>{
                if(val?.value?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()) )
                {
                    return true;
                }
                return false;
            })
            return list;
        })
        if(!isMulti){
            setSelectedValues([event?.target?.value])
        }
        // setIsFieldFocused(true);
        handleFieldResize();
    }

    const handleKeyPressAction = ()=>{
        setIsFieldFocused(true);
    }

    const handleFieldSelect = (value)=>(event)=>{
        let currentSelectedOptions = selectedValues;
        if(!isMulti){
            setSelectedValues([value]);
            handleFieldChange({label:name})([value]);
            handleFieldResize();
            checkErrorState(value, name, errorTypes);
            return ;
        }
        if(currentSelectedOptions?.includes(value))
        {
            currentSelectedOptions = currentSelectedOptions?.filter((val)=>{
                if(val == value)
                {
                    return false;
                }
                return true;
            })
        }
        else{
            currentSelectedOptions.push(value);
        }
        setSelectedValues(currentSelectedOptions);
        handleFieldChange({label:name})(currentSelectedOptions);
        handleFieldResize();
        checkErrorState(value, name, errorTypes);
    }

    const handleFieldFocus = (event)=>{
        setIsFieldFocused(!isFieldFocused);
    }

    const handleItemRemove = (value)=>()=>{
        const currentSelectedOptions = selectedValues || [];
        const leftOverValues = currentSelectedOptions?.filter((val)=>{
            if(val == value)
            {
                return false;
            }
            return true;
        })
        setSelectedValues(leftOverValues);
        handleFieldChange({label:name})(leftOverValues)
        handleFieldResize();
        checkErrorState(leftOverValues, name, errorTypes);
    }

    return (
        <div  ref={divRef} className={cx(styles.multiSelectContainer,{[styles.disabledState]: isDisabled})} onClick={handleFieldFocus}>
            <div className={styles.selectField}>
                <div className={styles.inputContainer} ref={inputRef}>
                    <div className={styles.innerInputContainer}>
                        {isMulti &&  selectedValues?.map((value)=>{
                            return (
                                <div className={styles.selectedOption}>
                                    {value}
                                    <img onClick={handleItemRemove(value)} src={require("./../../../assets/images/cancel_icon.svg").default} className={styles.cancelIcon} />
                                </div>
                            )
                        })}                
                        <input
                            onChange={handleListUpdate}
                            value={!isMulti ? selectedValues?.[0] : ""}
                            className={ (selectedValues?.length> 0 && isMulti) ? styles.noninput: cx(styles.input,inputContainerClass)}
                            type="text"
                            placeholder={selectedValues?.length> 0 ? "": placeholder }
                            onKeyDown={handleKeyPressAction}
                        />
                    </div>
                    <div className={styles.iconContainer}>
                        <img src={ isFieldFocused ? SearchIcon : DropDownIcon} className={styles.inputIcon} />
                    </div>
                </div>
            </div>
            <div>
                {CustomDropDownComponent && isFieldFocused &&
                    <div className={cx(styles.dropdownOptions, dropdownContainer)} style={{'width':`${popupWidth}px`}}>
                    {map(fieldOptions,({id,value, label, ...rest})=>{
                        const isSelected = selectedValues?.includes(value);
                        return (
                            <CustomDropDownComponent onClick={handleFieldSelect(value)} key={id} value={value} label={label} {...rest} />
                        )
                    })}
                    </div>                
                }
                {
                    isFieldFocused && !CustomDropDownComponent && 
                    <div className={cx(styles.dropdownOptions, dropdownContainer)} style={{'width':`${popupWidth}px`}}>
                    {map(fieldOptions,({id,value, label})=>{
                        const isSelected = selectedValues?.includes(value);
                        return (
                            <div className={styles.dropdownSingleOption} onClick={handleFieldSelect(value)} key={id}>
                                { isMulti && <input type="checkbox" name={id} value={value} checked={isSelected} />}
                                    <label for={id}> {label ? label : value}</label>
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        </div>
    )
}

export default MultiSelect;