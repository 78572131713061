import {map} from "lodash"
import { BILLING_DETAILS_FORM, BILLING_TYPES, CARE_PLAN_DETAIL_FORM, NOTES_FORM_FIELDS_BASE_NAMES, PLACEHOLDER_TEXTS, PRIVATE_NOTE_FORM } from "./notesFormPopup.constants"

import withDebounce from "../../../../hoc/withDebounce";
import Multiselect from "../../../../molecules/Multiselect"
import TextArea from "../../../../reusables/formManager/fieldComponents/TextArea/TextArea";
import ErrorComponent from "../../../../atoms/errorComponent/ErrorComponent";
import RichTextEditor from "../../../../molecules/RichTextEditor/RichTextEditor";
import FormManager from "../../../../reusables/formManager/FormManager";
import FormContent from "../../../../reusables/formManager/components/formContent";
import SubFormWrapper from "./component/SubFormWrapper";
import SingleSelect from "./component/SingleSelect";
import DetailedForm from "./component/NotesForms/DetailedForm";

import ScheduleFollowUp from "./component/ScheduleFollowUp/ScheduleFollowUp";
import ClinicalNotes from "./component/ClinicalNotes";

import styles from "./notesFormPopup.module.css"

const getCustomFieldProps = (name)=>{
    switch(name){
        case NOTES_FORM_FIELDS_BASE_NAMES.timeSpent :
            return {
                CustomInput : Multiselect,
                isMulti: false,
                PlaceHolder: "Choose Time Spent",
                options : [
                    {id:1,value:"5 min"},
                    {id:2,value:"10 min"},
                    {id:3,value:"15 min"},
                    {id:4,value:"20 min"},
                    {id:5,value:"25 min"},
                    {id:6,value:"30 min"}
                ],
                dropdownContainer : styles.dropdownContainer
            };
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.billingCode :
            return {
                CustomInput : Multiselect,
                isMulti: false,
                PlaceHolder: "Choose billing Code",
                inputContainerClass : styles.inputContainerClass,
                options : [
                    {
                        id : 1,
                        value : BILLING_TYPES["CPT 99426, 99427 - Principal Care Management"],
                        label : "CPT 99426, 99427 - Principal Care Management"
                    },
                    {
                        id : 2,
                        value : BILLING_TYPES["CPT 99457, 99458 - Remote Patient Monitoring"],
                        label : "CPT 99457, 99458 - Remote Patient Monitoring"
                    },
                ],
                dropdownContainer : styles.mainDropdown
            }
        case NOTES_FORM_FIELDS_BASE_NAMES.expressNote :
            return {
                CustomInput : RichTextEditor,
                labelClassName : styles.expressNoteLabel
            }
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.nonClinicalNote:
        case NOTES_FORM_FIELDS_BASE_NAMES.toProvider:
            return {
                CustomInput : RichTextEditor,
            }
        case NOTES_FORM_FIELDS_BASE_NAMES.patientCommunication : 
            return {
                CustomInput : TextArea,
                placeHolder : "Enter additional notes for patients"
            }
            break;
        case NOTES_FORM_FIELDS_BASE_NAMES.followUp :
            return {
                CustomInput: SingleSelect,
                isMulti: false,
                placeHolder : "Choose next follow-up date"
            }
        case NOTES_FORM_FIELDS_BASE_NAMES.carePlanSummary : 
            return {
                CustomInput : TextArea,
                placeHolder : "Enter Care Plan Summary",
                disabled : true
            }
    }
}

const getRespectivePlaceHolder = (name="")=>{
    if(!PLACEHOLDER_TEXTS[name]){
        return {}
    }
    return {placeHolder:PLACEHOLDER_TEXTS[name]}
}

const getNotesPopupFields = (notesFormFields=[]) =>{
    return map(notesFormFields,({name, label})=>{

        return {
            name,
            label,
            className : styles.formFieldContainer,
            ErrorComponent : ErrorComponent,
            onFocus:()=>{},
            onBlur:()=>{},
            ...getRespectivePlaceHolder(name),
            ...getCustomFieldProps(name)
        }
    })
}

const getDetailsAnanlysisForms = ({
        handleNotesFormUpdate=()=>{}
    })=>{
    
    const handleFormValuesUpdate = ({
        label = ""
    }) =>(internal)=>{
        handleNotesFormUpdate({
            [label] : internal
        })
    }

    
    return [{
            label : "Care Plan Detail",
            Component : FormContent,
            MainComponent : SubFormWrapper,
            name : "carePlanDetails",
            handleValueChange : handleFormValuesUpdate,
            bodyProps:{
                formContent : getNotesPopupFields(CARE_PLAN_DETAIL_FORM),
                handleFieldChange : handleFormValuesUpdate,
                formBodyClassname : styles.formBodyClassname
            }
        },
        {
            label : "Billing Detail",
            Component : FormContent,
            MainComponent : SubFormWrapper,
            handleValueChange : handleFormValuesUpdate,
            name : "billingDetails",
            bodyProps:{
                formContent : getNotesPopupFields(BILLING_DETAILS_FORM),
                handleFieldChange : handleFormValuesUpdate,
                formBodyClassname : styles.formBodyClassname
            }
        },
        {
            label : "Clinical Notes",
            Component : ClinicalNotes,
            MainComponent : SubFormWrapper,
            handleValueChange : handleFormValuesUpdate,
        },
        {
            label : "Schedule Follow-up",
            handleValueChange : handleFormValuesUpdate,
            Component : ScheduleFollowUp,
            MainComponent : SubFormWrapper
        },
        {
            label : "Private Note",
            Component : FormContent,
            MainComponent : SubFormWrapper,
            name : "privateNote",
            handleValueChange : handleFormValuesUpdate,
            bodyProps:{
                formContent : getNotesPopupFields(PRIVATE_NOTE_FORM),
                handleFieldChange : handleFormValuesUpdate,
                formBodyClassname : styles.formBodyClassname
            }
        }
    ]
}

const getRespectiveNotesType = (handleNotesFormUpdate) => ({
    Express : {
        Component : FormManager,
        containerClass : styles.expressNoteContainer
    },
    Detailed : {
        Component : DetailedForm,
        subForms : getDetailsAnanlysisForms({handleNotesFormUpdate}),
    }
})

export {
    getNotesPopupFields,
    getDetailsAnanlysisForms,
    getRespectiveNotesType
}