import React, { useCallback, useEffect, useRef, useState } from "react";
import {useNavigate} from "react-router-dom";

import { getArchiveActiveAction } from "./actionItem.async";
import styles from "./actionItem.module.css";
import { RRX_PRACTICE_ID } from "../../rrxCoach.constants";

const ActionItem = ({
        coachId,
        setIsLoading = ()=>{},
        updateCoachList = ()=>{},
        handleOpenToastDisplay=()=>{}
    }) =>{    
    const [options, setOptions] = useState(false);
    const navigate = useNavigate();

    const containerDiv = useRef();

    useEffect(()=>{
        document.addEventListener("mousedown",handleCloseDisplayCalender);
    
        return () => {
          document.removeEventListener("mousedown", handleCloseDisplayCalender);
        };
    },[]);

    const handleCloseDisplayCalender = useCallback(()=>{
        if (containerDiv.current && !containerDiv.current.contains(event.target)) {
            setOptions(false);
        }
    },[setOptions, containerDiv]);

    const handleMoveToActive=()=>{
        setIsLoading(true);
        getArchiveActiveAction({userId:coachId})
        .then((data)=>{
            if(data=="200" || data=="201" || data=="204"){
                handleOpenToastDisplay("success","Successfully Moved To Active");
                updateCoachList();
            }
            else{
                handleOpenToastDisplay("failed","Failed To Move To Active");
            }
        })
        .catch((e)=>{
            console.log("ERROR IS : ",e);
            handleOpenToastDisplay("failed","Failed To Move To Active");
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }

    const handleViewDetails = useCallback(()=>{
        navigate(`/rrxCoach/coachDetails/${RRX_PRACTICE_ID}/`+coachId);
    },[coachId])

    return (
        <div className={styles.container}>
            <div className={`${styles.mainContent} ${styles.linkButton}`} onClick={handleViewDetails}>
                View Details
            </div>
            <div className={styles.buttonGroup} ref={containerDiv}>
                <div className={styles.buttonExport} onClick={()=>{setOptions(!options)}}><img src={require("./../../../../../assets/images/more_options.svg").default}/></div>
                {options && <div className={styles.moveToArchive}>
                    <div className={styles.moveToArchiveContainer} onClick={handleMoveToActive}>Move to Active</div>
                </div>}
            </div>
        </div>
    )
}

export default ActionItem;