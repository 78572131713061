import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

import PatientsInvite from "../../organisms/patientsInvite";
import { USER_ROLES } from "../../constants/keyConstants";

import styles from "./patients.module.css";

const Patients = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        const userRole = window.localStorage.getItem("userRole");
        if(userRole == USER_ROLES.SUPER_ADMIN){
            navigate("/superUser")
        }
    },[]);

    return (
        <div className={styles.container}>
            <PatientsInvite />
        </div>
    )
}

export default Patients;